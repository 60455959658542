import React from 'react';
import Hero from '../Hero';
import Section from '../Section';
import SmallLink from '../SmallLink';
import InstagramEmbed from 'react-instagram-embed';

export const S4TKClubContent = ({
  hero,
  heading,
  subheading,
  blurb,
  cta,
  instagram,
  embed
}) => {
  return (
    <>
      <Hero images={hero.images} title={hero.text} />
      <Section>
        <p className="font-baloo mb-4 font-semibold text-s4tk-blue text-3xl text-left sm:text-justify px-0 md:px-4 lg:px-8">
          {heading}
        </p>
        <p className="font-baloo text-s4tk-blue text-2xl mb-4 text-left sm:text-justify">
          {subheading}
        </p>
        <p className="font-baloo text-black text-xl mb-4 text-left sm:text-justify">
          {blurb}
        </p>
        <SmallLink item={cta} />
      </Section>
      <Section>
        <p className="font-baloo mb-4 font-semibold text-s4tk-blue text-3xl text-left sm:text-justify px-0 md:px-4 lg:px-8">
          {instagram.text}
        </p>
        <div className="w-full flex justify-center items-center">
          <InstagramEmbed
            url="https://www.instagram.com/p/CA--HPPlO25/"
            clientAccessToken="432361474595399|0e64fe34f55a33ca93a886342b184959"
            hideCaption={false}
            className="w-full flex items-center justify-center"
          />
        </div>
      </Section>
    </>
  );
};

export default S4TKClubContent;
