import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import S4TKClubContent from '../components/About/S4TKClubContent';

export const S4TKClubPageTemplate = ({
  hero,
  heading,
  subheading,
  blurb,
  cta,
  instagram,
  embed
}) => {
  return (
    <div className="w-full flex flex-col bg-s4tk-white">
      <S4TKClubContent
        hero={hero}
        heading={heading}
        subheading={subheading}
        blurb={blurb}
        cta={cta}
        instagram={instagram}
        embed={embed}
      />
    </div>
  );
};

const S4TKClubPage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark;

  return (
    <Layout>
      <S4TKClubPageTemplate
        hero={frontmatter.hero}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        blurb={frontmatter.blurb}
        cta={frontmatter.cta}
        instagram={frontmatter.instagram}
        embed={html}
      />
    </Layout>
  );
};

export default S4TKClubPage;

export const s4tkClubPageQuery = graphql`
  query S4TKClubPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "s4tk-club-page" } }) {
      frontmatter {
        hero {
          images {
            image {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          text
        }
        heading
        subheading
        blurb
        cta {
          title
          icon {
            prefix
            title
          }
          url
        }
        instagram {
          text
        }
      }
      html
    }
  }
`;
